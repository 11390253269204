<template>
	<div class="wrapper">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>关于我们</span>
			</div>
			<el-skeleton :loading="loading" animated :count="5">
				<el-tabs v-model="active" tab-position="left">
					<el-tab-pane label="淘街坊" name="淘街坊">
						<div class="pane-box">
							<div class="title">
								<i class="el-icon-caret-right"></i>
								关于公司
							</div>
							<div class="description">
								<div class="desc-content">
									<p>广州全民互娱网络科技有限公司是一家成立于2018年集网络游戏服务、互联网商品销售、市场营销策划等服务的科技公司，其中“淘街坊App”(以下简称“淘街坊”)是广州全民互娱网络科技有限公司旗下的一款接入平台优惠券为用户随时随地提供购物、餐饮、休闲娱乐及生活服务等领域的消费优惠，拥有强大的售后保障，扎根选品，在同类APP中有选品优势，为用户提供高性价比的商品推荐，每个网购用户及团队都可以下载淘街坊，成为淘街坊的合作伙伴的手机端应用软件</p>
								</div>
								<el-image class="desc-image" src="http://img.taojiefang.cn/Fm4CLXvgLNriNO8QPilSsYRX8Scg"></el-image>
							</div>
							<div class="title">
								<i class="el-icon-caret-right"></i>
								关于淘街坊
							</div>
							<div class="description">
								<div class="desc-image">
									<el-image src="http://img.taojiefang.cn/FvNyiZcbrGx6CowEbCoAzJLs5Nxr"></el-image>
								</div>
								<div class="desc-content">
									<p>淘街坊与淘宝、天猫、京东、拼多多、唯品会、飞猪、美团、饿了么等大型平台建立深度合作，通过平台间无缝连接，实现全网商品优惠搜索，共同打造集“衣食住行游购娱”于一体的超级生活入口，实现购物省钱，满足网购爱好者对品质好货与极致性价比的追求，并同时享受大平台购物权益保障。</p>
									<p>生活服务优惠推荐<br/>淘街坊服务覆盖衣食住行、健康、娱乐、教育等全方位生活领域。接入生活服务平台，提供给消费者优惠折扣和选择。</p>
									<p>淘街坊，源头货一站式购物<br/>淘街坊通过社交互动等方式直接将各地的源头产地产品、工厂直销产品与消费者进行连接</p>
									<p>未来，淘街坊将继续以大数据为载体，整合多方资源，构建分享价值网络，实现消费者、平台、商家的共赢，不断成长为一个有温度，且能够持续赋能个人与企业、持续创造社会价值的平台。</p>
								</div>
							</div>
							<div class="title">
								<i class="el-icon-caret-right"></i>
								下载淘街坊App
							</div>
							<div class="description">
								<div class="desc-content">
									<p>苹果、华为、小米、应用宝、OPPO、VIVO、魅族等大型应用商店直接搜索关键字 “淘街坊” 即可以下载</p>
								</div>
								<div class="desc-image">
									<el-image class="download-image" src="http://img.taojiefang.cn/FpoXw4JGmLK6II90NKx3TetClvgC"></el-image>
								</div>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="联系我们" name="联系我们">
						<div class="pane-box">
							<div class="title">
								<i class="el-icon-caret-right"></i>
								公司联系方式
							</div>
							<div class="description">
								<div>
									<p>公司电话：020-22043458</p>
									<p>公司地址：广州市番禺区番禺区大龙街新社大街11号之一202</p>
									<p>客服微信：a595373</p>
								</div>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="招贤纳士" name="招贤纳士">
						<div class="pane-box">
							<div class="table">
								<el-descriptions title="运营专员" :column="2" border>
									<el-descriptions-item label="工作地点">广州番禺</el-descriptions-item>
									<el-descriptions-item label="工作性质">运营专员</el-descriptions-item>
									<el-descriptions-item label="招聘人数">2人</el-descriptions-item>
									<el-descriptions-item label="性别要求">无</el-descriptions-item>
									<el-descriptions-item label="工资待遇">面议</el-descriptions-item>
									<el-descriptions-item label="工作经验">1-3年</el-descriptions-item>
									<el-descriptions-item label="学历要求">大专</el-descriptions-item>
									<el-descriptions-item label="语言能力">不限制</el-descriptions-item>
								</el-descriptions>
							</div>
							<div class="title">
								<i class="el-icon-caret-right"></i>
								职位描述
							</div>
							<div class="description">
								<div>
									<p>1.负责公司电商平台的线上策划活动的相关运营方案</p>
									<p>2.制定整体执行计划、把控项目进度、推动跨部门沟通使项目有效推进</p>
									<p>3.复盘活动，进行分析优化</p>
									<p>4.保持行业观察，挖掘新型活动和营销模式</p>
									<p>5.Macbook办公，人体工程学座椅，无限零食，不定期团建，年度境外旅游，以及标配的社保等</p>	
								</div>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</el-skeleton>
		</el-card>
	</div>
</template>

<script>
import Vue from 'vue';
import { Skeleton, SkeletonItem, Card, Empty, Image, Tabs, TabPane, Icon, Descriptions, DescriptionsItem } from 'element-ui';

Vue.use(Skeleton).use(SkeletonItem).use(Card).use(Tabs).use(TabPane).use(Empty).use(Image).use(Icon).use(Descriptions).use(DescriptionsItem)

export default {
	components: {},
	data () {
		return {
			loading: true,
			active: '淘街坊'
		}
	},
	computed: {},
	created () {},
	mounted () {
		setTimeout(() => {
			this.loading = false
		}, 1000)
	},
	methods: {}
}
</script>

<style scoped>
.box-card  {
	margin-top: 10px;
	min-height: 600px;
}

.pane-box {
	padding: 20px;
}

.description p {
	text-indent: 30px;
	font-size: 14px;
	color: #333;
	line-height: 40px;
}

.title {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 20px;
}

.description {
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;
}

.table {
	margin-bottom: 30px;
}

.desc-content {
	width: 400px;
	padding-right: 10px;
}

.desc-image {
	flex: 1;
}

.download-image {
	margin-left: 30px;
	width: 150px;
}
</style>
